













































import LandingSection from '@/components/LandingSection.vue'
import { normalizeResourceName } from '@/utils/misc'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LandingSectionTrademarks',

  components: {
    LandingSection,
  },

  setup() {
    const brandNames = [
      'ALTAMIRA',
      'AQUATROL',
      'AZUD',
      'Clack',
      'FPZ',
      'GLA',
      'GRUNDFOS',
      'HACH',
      'HAYWARD',
      'HYDRANAUTICS',
      'Kasco',
      'Myers',
      'PENTAIR',
      'WATTS',
    ]

    const brands = brandNames.map((name) => ({
      title: `${name} logo`,
      imageSrc: `@/assets/images/brands/${normalizeResourceName(name)}.png`,
    }))

    return {
      brands,
    }
  },
})
